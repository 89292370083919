import React, { useState, useRef, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import gsap from "gsap";

import Navigation from "./Navigation";
import Logo from "./Logo";
import Burger from "./Burger";
import { focusableSelectors } from "utils/dom/focus";
import { KEY_ESCAPE, KEY_TAB } from "utils/dom/keyCodes";

import "./_header.scss";

const Header = ({ main, homes, logo, logoAlt, isDark }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef();
  const navWrapperRef = useRef(null);
  const trapStartRef = useRef(null);
  const trapEndRef = useRef(null);

  const getFocusableNodes = () => {
    if (navWrapperRef && navWrapperRef.current) {
      const focusableNodes = navWrapperRef.current.querySelectorAll(
        focusableSelectors.join(", ")
      );
      return Array.from(focusableNodes);
    }

    return [];
  };

  const focusContentNode = () =>
    navWrapperRef && navWrapperRef.current && navWrapperRef.current.focus();

  const focusFirstFocusable = () => {
    const focusableNodes = getFocusableNodes();
    if (focusableNodes.length > 0) {
      focusableNodes[0].focus();
    } else {
      focusContentNode();
    }
  };

  const focusLastFocusable = () => {
    const focusableNodes = getFocusableNodes();
    if (focusableNodes.length > 0) {
      focusableNodes[focusableNodes.length - 1].focus();
    } else {
      focusContentNode();
    }
  };

  const handleBackwardTab = event => {
    window.setTimeout(() => {
      if (
        (trapStartRef &&
          trapStartRef.current &&
          trapStartRef.current === document.activeElement) ||
        (navWrapperRef &&
          navWrapperRef.current &&
          !navWrapperRef.current.contains(document.activeElement))
      ) {
        event.preventDefault();
        focusLastFocusable();
      }
    }, 0);
  };

  const handleForwardTab = event => {
    window.setTimeout(() => {
      if (
        (trapEndRef &&
          trapEndRef.current &&
          trapEndRef.current === document.activeElement) ||
        (navWrapperRef &&
          navWrapperRef.current &&
          !navWrapperRef.current.contains(document.activeElement))
      ) {
        event.preventDefault();
        focusFirstFocusable();
      }
    }, 0);
  };

  const handleKeyDown = event => {
    const keyCode = event.which || event.keyCode || 0;

    switch (keyCode) {
      case KEY_ESCAPE:
        setIsMenuOpen(false);
        break;
      case KEY_TAB:
        if (event.shiftKey) {
          handleBackwardTab(event);
        } else {
          handleForwardTab(event);
        }
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    const removeFocusHandler = () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };

    return removeFocusHandler;
  }, []);

  const [homeNavItems] = useState(
    homes.map(item => {
      return {
        ...item,
        ref: createRef(),
      };
    })
  );

  const [mainItems] = useState(
    main.map(item => {
      return {
        ...item,
        ref: createRef(),
      };
    })
  );

  const [menuTL] = useState(
    gsap.timeline({
      paused: true,
      defaults: { duration: 1, ease: "expo.out" },
    })
  );

  useEffect(() => {
    const mainItemsRefs = mainItems.map(item => item.ref.current);
    const homeItemsRefs = homeNavItems.map(item => item.ref.current);

    menuTL
      .fromTo(
        navRef.current,
        { x: "100%", width: "0" },
        { x: 0, width: "100%" }
      )
      .fromTo(
        homeItemsRefs,
        { autoAlpha: 0, y: 48 },
        { autoAlpha: 1, y: 0, stagger: 0.1 },
        "-=0.8"
      )
      .fromTo(
        mainItemsRefs,
        { autoAlpha: 0, y: 48 },
        { autoAlpha: 1, y: 0, stagger: 0.1 },
        "-=0.8"
      )
      .reverse();
  }, [homeNavItems, mainItems]);

  useEffect(() => {
    menuTL.reversed(!isMenuOpen);
  }, [isMenuOpen]);

  useEffect(() => {
    isMenuOpen && document.body.classList.add("menu--open");
    !isMenuOpen && document.body.classList.remove("menu--open");
  }, [isMenuOpen]);

  const toggleNav = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header__content">
        <Logo logo={logo} logoAlt={logoAlt} />
        <div className="header__focus-trap-wrapper">
          <span className="focus-trap" ref={trapStartRef} tabIndex={0} />
          <div className="header__focus-trap" ref={navWrapperRef}>
            <Navigation
              mainNavitems={mainItems}
              homeNavitems={homeNavItems}
              isMenuOpen={isMenuOpen}
              ref={navRef}
              toggleNav={toggleNav}
            />
            <Burger
              toggleNav={toggleNav}
              isMenuOpen={isMenuOpen}
              isDark={isDark}
            />
          </div>
          <span className="focus-trap" ref={trapEndRef} tabIndex={0} />
        </div>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  main: PropTypes.array.isRequired,
  logo: PropTypes.object,
  logoAlt: PropTypes.string,
};
