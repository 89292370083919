import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import classNames from "classnames";

import "./_newsletter.scss";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const messageClassName = classNames("newsletter__message", {
    "newsletter__message--error": error,
  });

  const handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(email)
      .then(data => {
        if (data.result === "success") {
          setError(false);
          setMessage("Ačiū. Laukite naujienų savo el. pašto dėžutėje!");
        } else if (data.result === "error") {
          setError(true);
          setMessage(
            "Prenumerata nepavyko. Patikrinkite įvestą el. pašto adresą ir bandykite dar kartą."
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className="newsletter">
      <div className="newsletter__fields">
        <input
          placeholder="El. paštas"
          className="newsletter__input"
          name="email"
          type="text"
          onChange={handleEmailChange}
        />
        <button className="newsletter__button" type="submit">
          Prenumeruoti
        </button>
      </div>
      {message && <p className={messageClassName}>{message}</p>}
    </form>
  );
};

export default Newsletter;
