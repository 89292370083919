export const products = [
  {
    id: "24c40305-aa24-477a-9f07-a8c36b1a5252",
    title: "Sweden 2",
    slug: "sweden-2",
    img: {
      src: "sweden2.jpg",
      alt: "modulinis namelis sweden moodu",
    },
  },
  {
    id: "e594e82a-8721-420c-a5a0-8ab87e568368",
    title: "Denver",
    slug: "denver",
    img: {
      src: "denver.jpg",
      alt: "modulinis namas denver moodu",
    },
  },
  {
    id: "1f7c4cf6-9e5b-4abb-8f7d-c1c3136457d3",
    title: "Rio",
    slug: "rio",
    img: {
      src: "rio.jpg",
      alt: "modulinis namelis Rio moodu",
    },
  },
];
