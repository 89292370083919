import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./_button.scss";

const Button = ({ url, children, isDark, isMain, isSamePage }) => {
  const buttonClassName = classNames("button", {
    "button--dark": isDark,
    "button--main": isMain,
  });
  return (
    <div className="button__wrapper">
      {isSamePage ? (
        <AnchorLink to={`/${url}`} className={buttonClassName}>{children}</AnchorLink>
        ) : (
          <AniLink
        cover
        duration={1.5}
        bg="#3e563e"
        className={buttonClassName}
        to={url}
      >
        {children}
      </AniLink>
        )}
      
    </div>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDark: PropTypes.bool,
  isMain: PropTypes.bool,
};

export default Button;
