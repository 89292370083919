import React from "react";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import SocialBar from "components/SocialBar";
import Heading from "components/Heading";
import Newsletter from "components/Newsletter";

import "./_footer.scss";

const Footer = ({ logo }) => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__main-info">
          <div className="footer__logo">
            <Img fluid={logo} alt="moodu logo" />
            <SocialBar
              fb="https://www.facebook.com/moodu.eu/"
              ig="https://www.instagram.com/moodu.eu/"
              yt="https://www.youtube.com/channel/UCovSIwd5Ni4XhretJShuuKQ"
              className="footer__social"
            />
          </div>
          <div className="footer__navigation-block">
            <Heading headingLevel="h3" headingStyle="h4">
              Moduliniai namai:
            </Heading>
            <AniLink
              to={`/sweden-2`}
              className="footer__link"
              activeClassName="footer__link--active"
              cover
              duration={1.5}
              bg="#3e563e"
            >
              Sweden 2
            </AniLink>
            <AniLink
              to={`/denver`}
              className="footer__link"
              activeClassName="footer__link--active"
              cover
              duration={1.5}
              bg="#3e563e"
            >
              Denver
            </AniLink>
            <AniLink
              to={`/rio`}
              className="footer__link"
              activeClassName="footer__link--active"
              cover
              duration={1.5}
              bg="#3e563e"
            >
              Rio
            </AniLink>
          </div>

          <div className="footer__navigation-block">
            <Heading headingLevel="h3" headingStyle="h4">
              Rezervuokite:
            </Heading>
            <a className="footer__link" href="tel:+37060442663">
              +370 604 42663
            </a>
            <a className="footer__link" href="mailto:info@moodu.eu">
              info@moodu.eu
            </a>
          </div>
          <div className="footer__newsletter">
            <Heading headingLevel="h3" headingStyle="h4">
              Prenumeruokite mūsų naujienlaiškį:
            </Heading>
            <Newsletter />
          </div>
        </div>

        <div className="footer__copyright">
          <div className="footer__copyrigth-block">
            © 2020 Moodu.
            <AniLink
              to={`/privatumo-politika`}
              className="footer__link footer__link--small"
              activeClassName="footer__link--active"
              cover
              duration={1.5}
              bg="#3e563e"
            >
              Privatumo politika
            </AniLink>
          </div>
          <p className="footer__author">
            Created by:
            <a
              className="footer__link footer__link--small"
              target="_blank"
              rel="noopener noreferrer"
              href="https://tamulaitis.com"
            >
              R. Tamulaitis
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
