import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./_layout.scss";

const Layout = ({ children, isBlog }) => {
  const layoutClassName = classNames("layout__content", {
    "layout__content--blog": isBlog,
  });
  return (
    <main className="layout">
      <div className={layoutClassName}>{children}</div>
    </main>
  );
};

Layout.propTypes = {
  isBlog: PropTypes.bool,
};

export default Layout;
