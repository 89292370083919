import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { KEY_TAB } from "utils/dom/keyCodes";

import "./_burger.scss";

const Burger = ({ toggleNav, isMenuOpen, isDark }) => {
  const [removeOutline, setRemoveOutline] = useState(true);
  const isExpanded = isMenuOpen ? true : false;
  const menuId = "main-menu";
  const burgerClassName = classNames("burger", {
    "burger--open": isMenuOpen,
    "burger--dark": isDark,
    "burger--no-outline": removeOutline,
  });

  const handleKeyDown = event => {
    const keyCode = event.which || event.keyCode || 0;

    if (keyCode === KEY_TAB) {
      setRemoveOutline(false);
    } else {
      setRemoveOutline(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    const removeFocusHandler = () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };

    return removeFocusHandler;
  }, []);

  return (
    <button
      aria-label="Toggle Menu"
      aria-expanded={isExpanded}
      aria-controls={menuId}
      className={burgerClassName}
      onClick={() => toggleNav()}
    >
      <span className="burger__line" />
      <span className="burger__line" />
      <span className="burger__line" />
    </button>
  );
};

export default Burger;
