export const homesNavigation = [
  {
    id: "5258d430-d729-44b1-96ad-484c005d",
    name: "Sweden 2",
    slug: "sweden-2",
  },
  {
    id: "94596dd2-3e95-4587-bdfd-4f48af31b06d",
    name: "Denver",
    slug: "denver",
  },
  {
    id: "2fa83d93-e460-4298-a07d-70d0b3642397",
    name: "Rio",
    slug: "rio",
  },
];

export const mainNavigation = [
  {
    id: "-oldrkeovjja0jz7h4g7a-",
    name: "Apie mus",
    slug: "apie-mus",
  },
  {
    id: "-sjidqo4j0419bm128fbw-",
    name: "Blogas",
    slug: "blogas",
  },
  {
    id: "2sjidqo4j4419bm128fbw-",
    name: "Kontaktai",
    slug: "kontaktai",
  },
];
