import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { FacebookIcon, InstagramIcon, YoutubeIcon } from "assets/icons";

import "./_social-bar.scss";

const SocialBar = ({ fb, ig, yt, isDark }) => {
  const socialBarClassName = classNames("social-bar", {
    "social-bar--dark": isDark,
  });

  return (
    <div className={socialBarClassName}>
      <a
        href={fb}
        className="social-bar__link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon />
      </a>
      <a
        href={ig}
        className="social-bar__link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon />
      </a>
      <a
        href={yt}
        className="social-bar__link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <YoutubeIcon />
      </a>
    </div>
  );
};

SocialBar.propTypes = {
  fb: PropTypes.string,
  ig: PropTypes.string,
  yt: PropTypes.string,
  isDark: PropTypes.bool,
};

export default SocialBar;
