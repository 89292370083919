import React from "react";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "./_logo.scss";

const Logo = ({ logo, logoAlt }) => {
  return (
    <AniLink to="/" cover duration={1.5} bg="#3e563e">
      <div className="logo">
        <Img fluid={logo} alt={logoAlt} />
      </div>
    </AniLink>
  );
};

export default Logo;
