import React, { forwardRef, useRef } from "react";
import classNames from "classnames";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Heading from "components/Heading";
import SocialBar from "components/SocialBar";

import "./_navigation.scss";

const Navigation = forwardRef(
  ({ toggleNav, homeNavitems, mainNavitems, isMenuOpen }, ref) => {
    const isHidden = isMenuOpen ? true : false;
    const menuId = "main-menu";
    const tabIndex = isHidden ? 0 : -1;
    const NavigationClassName = classNames("navigation", {
      "navigation--open": isMenuOpen,
    });

    let homeBackground = useRef(null);

    const handleHome = city => {
      homeBackground.classList.add(`navigation__background-inner--${city}`);
    };

    const handleHomeReturn = city => {
      homeBackground.classList.remove(`navigation__background-inner--${city}`);
    };

    return (
      <div className={NavigationClassName} ref={ref}>
        <div className="navigation__background">
          <div
            className="navigation__background-inner"
            ref={el => {
              homeBackground = el;
            }}
          ></div>
        </div>
        <div className="navigation__wrapper">
          <nav
            className="navigation__inner"
            id={menuId}
            aria-hidden={!isHidden}
          >
            <ol className="navigation__list">
              {homeNavitems.map(item => (
                <li
                  key={item.id}
                  ref={item.ref}
                  className="navigation__item navigation__item--ordered"
                >
                  <AniLink
                    to={`/${item.slug}`}
                    className="navigation__link navigation__link--ordered"
                    activeClassName="navigation__link--active"
                    tabIndex={tabIndex}
                    onMouseEnter={() => handleHome(item.slug)}
                    onMouseOut={() => handleHomeReturn(item.slug)}
                    cover
                    duration={1.5}
                    bg="#3e563e"
                  >
                    {item.name}
                  </AniLink>
                </li>
              ))}
            </ol>
            <div className="navigation__main-wrapper">
              <ul className="navigation__list">
                {mainNavitems.map(item => (
                  <li key={item.id} ref={item.ref} className="navigation__item">
                    <AniLink
                      to={`/${item.slug}`}
                      className="navigation__link"
                      activeClassName="navigation__link--active"
                      tabIndex={tabIndex}
                      cover
                      duration={1.5}
                      bg="#3e563e"
                    >
                      {item.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
              <div className="navigation__contacts">
                <div className="navigation__contacts-main">
                  <Heading headingLevel="h2" headingStyle="h4">
                    Rezervuokite:
                  </Heading>
                  <a
                    className="navigation__link navigation__link--small"
                    href="tel:+370 604 42663"
                  >
                    +370 604 42663
                  </a>
                  <a
                    className="navigation__link navigation__link--small"
                    href="mailto:info@moodu.eu"
                  >
                    info@moodu.eu
                  </a>
                </div>
                <div className="navigation__contacts-info">
                  <Heading headingLevel="h3" headingStyle="h4">
                    Sekite mus:
                  </Heading>
                  <SocialBar
                    fb="https://www.facebook.com/moodu.eu/"
                    ig="https://www.instagram.com/moodu.eu/"
                    yt="https://www.youtube.com/channel/UCovSIwd5Ni4XhretJShuuKQ"
                  />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
);

export default Navigation;
