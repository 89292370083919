import React from "react";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "components/Button";

import "./_banner.scss";

const Banner = ({
  heading,
  text,
  buttonText,
  buttonUrl,
  imageData,
  isCentered,
  isInnerPage,
  isProduct,
  isSamePageUrl
}) => {
  const bannerContentClassName = classNames("banner__content", {
    "banner__content--centered": isCentered,
  });
  const bannerClassName = classNames("banner", {
    "banner--inner": isInnerPage,
    "banner--product": isProduct,
  });
  return (
    <BackgroundImage Tag="div" className={bannerClassName} fluid={imageData}>
      <div className={bannerContentClassName}>
        {heading && <h1 className="banner__heading">{heading}</h1>}
        {text && <p className="banner__text">{text}</p>}
        {buttonUrl && <Button isSamePage={isSamePageUrl} url={buttonUrl}>{buttonText}</Button>}
      </div>
    </BackgroundImage>
  );
};

Banner.propTypes = {
  imageData: PropTypes.object,
  heading: PropTypes.string,
  isCentered: PropTypes.bool,
  isInnerPage: PropTypes.bool,
  isProduct: PropTypes.bool,
  text: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  isSamePageUrl: PropTypes.bool,
};

export default Banner;
